import React from "react";

function Themes() {

    return <section className="services section">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="section-title">
                        <h2>THEMES ABORDES</h2>
                        <img src="img/section-img.png" alt="#" />
                            <p></p>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-4 col-md-6 col-12">
                    <div className="single-service">
                        <i className="fa fa-arrow-right"></i>
                        <h4><a>SANTE SEXUELLE</a></h4>
                        <p></p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12">
                    <div className="single-service">
                        <i className="fa fa-arrow-right"></i>
                        <h4><a>PREVENTION DES GROSSESSES PRECOCES</a></h4>
                        <p></p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12">
                    <div className="single-service">
                        <i className="fa fa-arrow-right"></i>
                        <h4><a>HYGIENE MENSTRUELLE</a></h4>
                        <p></p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12">
                    <div className="single-service">
                        <i className="fa fa-arrow-right"></i>
                        <h4><a>VIH ET IST</a></h4>
                        <p></p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12">
                    <div className="single-service">
                        <i className="fa fa-arrow-right"></i>
                        <h4><a>DROGUES ET STUPEFIANT</a></h4>
                        <p></p>
                    </div>

                </div>
                <div className="col-lg-4 col-md-6 col-12">
                    <div className="single-service">
                        <i className="fa fa-arrow-right"></i>
                        <h4><a>VIOLENCE BASEE SUR LE GENRE</a></h4>
                        <p></p>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-4 col-md-6 col-12">
                    <div className="single-service">
                        <i className="fa fa-arrow-right"></i>
                        <h4><a>INTERRUPTION VOLONTAIRE DE GROSSESSE</a></h4>
                        <p></p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12">
                    <div className="single-service">
                        <i className="fa fa-arrow-right"></i>
                        <h4><a>PLANNING FAMILIALE</a></h4>
                        <p></p>
                    </div>
                </div>


            </div>
        </div>
    </section>;

}

export default Themes;