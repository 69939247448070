import React, {useEffect, useState} from "react";
import {NavLink} from "react-router-dom";
import axiosInstance from "../../configurations/axiosInstance/AxiosInstance";
import logo from '../img/logo.png';
import unfpa from '../img/unfpa.png';

function Header() {
    const [links, setLinks] = useState([]);
    const fetchContact = ()=>{
        axiosInstance()
            .get("api/parametres/get/links")
            .then((response)=>{
                let data = response.data;
                let fetchedLinks = [];

                if (data != null) {
                    if (data.call != null) {
                        fetchedLinks.push(
                            <li key="call" title={data.call.valeur}>
                                <NavLink to={`tel:${data.call.valeur}`}><i className="fa fa-phone"></i></NavLink>
                            </li>
                        );
                    }
                    if (data.facebook != null) {
                        fetchedLinks.push(
                            <li key="facebook" title={data.facebook.valeur}>
                                <NavLink to={data.facebook.valeur}><i className="fa fa-facebook-official"></i></NavLink>
                            </li>
                        );
                    }
                    if (data.sms != null) {
                        fetchedLinks.push(
                            <li key="sms" title={data.sms.valeur}>
                                <NavLink to={`sms:${data.sms.valeur}`}><i className="fa fa-commenting"></i></NavLink>
                            </li>
                        );
                    }
                    if (data.tweeter != null) {
                        fetchedLinks.push(
                            <li key="tweeter" title={data.tweeter.valeur}>
                                <NavLink to={data.tweeter.valeur}><i className="fa fa-twitter"></i></NavLink>
                            </li>
                        );
                    }
                    if (data.whatsapp != null) {
                        fetchedLinks.push(
                            <li key="whatsapp" title={data.whatsapp.valeur}>
                                <NavLink to={`https://api.whatsapp.com/send?phone=${data.whatsapp.valeur}`}><i className="fa fa-whatsapp"></i></NavLink>
                            </li>
                        );
                    }
                }

                setLinks(fetchedLinks);
            }).catch((error)=>{})
    }

    useEffect(
        ()=>{
            fetchContact()
        },
        []
    );


    return <>
        <header className="header">
            <div className="topbar">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-5 col-12">
                            <ul className="top-link">
                                <li><a href='/denonciation'>Denonciations</a></li>
                                <li><a href='/about'>A Propos</a></li>
                            </ul>
                        </div>
                        <div className="col-lg-6 col-md-7 col-12">
                            <ul className="top-contact">{links}</ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="header-inner">
                <div className="container">
                    <div className="inner">
                        <div className="row">
                            <div className="col-lg-3 col-md-3 col-12">
                                <div className="logo">
                                    <a><img src={logo} alt="#" /></a>
                                </div>
                                <div className="mobile-nav"></div>
                            </div>
                            <div className="col-lg-7 col-md-9 col-12">
                                <div className="main-menu">
                                    <nav className="navigation">
                                        <ul className="nav menu">
                                            <li><a href='/'>Acceuil</a></li>
                                            <li>
                                                <a>Notre Travail </a>
                                                <ul className="dropdown">
                                                    <li><a href='/work/SANTE_SEXUELLE'>Sante Sexuele</a></li>
                                                    <li><a href='/work/GROSSESSE_PRECOSE'>Prevention des grossesses
                                                        precoces</a></li>
                                                    <li><a href='/work/HYGENE_MENSTRUELLE'>Hygiene Menstruelle</a></li>
                                                    <li><a href='/work/VIH_IST'>VIH & IST</a></li>
                                                    <li><a href='/work/DROGUE_STUPEFIANT'>Drogues & Stupefiants</a></li>
                                                    <li><a href='/work/VVG'>Violence basee sur le genre</a></li>
                                                    <li><a href='/work/IVG'>Interruption Volontaire de
                                                        grossesses</a></li>
                                                    <li><a href='/work/PLANNING_FAMILLIAL'>Planning Familiale</a></li>
                                                </ul>
                                            </li>
                                            <li><a href='/service'>Services</a></li>
                                            <li><a href='/carte'>Carte</a></li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                            <div className="col-lg-2 col-12">
                                <div className="logo">
                                    <a onClick={() => window.location.href = 'https://www.unfpa.org/fr'} target="_blank">
                                        <img style={
                                        {
                                            width: '158px',
                                            height: '50px',

                                        }
                                    } src={unfpa} alt="#" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        </>;

}

export default Header;