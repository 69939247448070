import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import axiosInstance from "../../configurations/axiosInstance/AxiosInstance";
import section from "../img/section-img.png";
import image from "../img/cover/Health-Insurance.jpg";


function CentrePartenaires() {

    const {id} = useParams();
    const [body, setBody]= useState([]);

    const fetchCP = () => {
        let title = "CENTRE PARTENAIRE: ";
        let errorMessage = document.getElementById('error');
        let preloader = document.getElementById('preloader');
        preloader.classList.remove("preloader-deactivate");

        axiosInstance().get('service/list-by-cp-id/'+id)
            .then(function (response) {

                let data = response.data;
                if((data == null)||(data.length===0)){
                    errorMessage.style.display = 'block';
                }else{
                    let fetchedData = [];
                    title += data[0].centrePartenaire.nom;
                    document.getElementById("cp").innerHTML = title;
                    for (let index = 0; index < data.length; index++) {
                        fetchedData.push(
                            <tr style={
                                {
                                    minHeight: '230px',
                                    maxHeight: '230px'
                                }
                            }>
                                <td style={
                                    {
                                        width: '230px',
                                        padding: '0'
                                    }
                                }>
                                    <img src={image} width="230px" height="230px"  style={
                                        {
                                            minHeight: '230px',
                                            maxHeight: '230px'
                                        }
                                    } />
                                </td>
                                <td>
                                    <div>
                                        <p><strong>Nom du Service: </strong>{data[index].libelle} </p>
                                        <p><strong>Centre Partenaire: </strong>{data[index].centrePartenaire.nom}</p>
                                        <p><strong>Telephone: </strong>{data[index].centrePartenaire.telephone}</p>
                                        <p><strong>localisation: </strong>{data[index].centrePartenaire.location}</p>
                                        <div><a href="https://client.youthfp.cm/login" target="_blank" className="btn">Souscrire</a></div>

                                    </div>
                                </td>

                            </tr>
                        )
                    }
                    errorMessage.style.display = 'none';
                    setBody(fetchedData);
                    preloader.classList.add("preloader-deactivate");
                }
            })
            .catch(function (error) {
                errorMessage.style.display = 'none';
                preloader.classList.add("preloader-deactivate");
            });

    };

    useEffect(
        ()=>{
            document.title='Centre Partenaire';
            fetchCP();
        },[]
    )

    return <section className="blog section" id="blog">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="section-title">
                        <h2 id="cp"></h2>
                        <img src={section} alt="#" />
                            <p>Liste des services</p>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">

                    <div className="table-responsive-lg">
                        <table className="table">
                            <tbody>
                            {body}

                            </tbody>
                        </table>
                    </div>
                </div>
                <div id="error">
                    <p>Ce partenaire n'existe pas / plus</p>
                </div>

            </div>
        </div>
    </section>;

}

export default CentrePartenaires;