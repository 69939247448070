import React, {useEffect, useState} from "react";
import {NavLink} from "react-router-dom";
import axiosInstance from "../../configurations/axiosInstance/AxiosInstance";
import {toastClick} from "../toast/CustomToast";

function RegisterLogin() {
    const [quizz, setQuizz] = useState([]);

    const handleQuizClick = (id) => {
        axiosInstance().get('test/api/sondage/' + id)
            .then(function (response) {
                let data = response.data;
                if(data===null){
                    toastClick("Sondage non existant", "info");
                }else{
                    window.location.href = "/quizz/"+id;
                }
            })
            .catch(function (error) {
                toastClick("Sondage non existant", "error");
            });
    }

    const fetchQuizz = ()=>{
        axiosInstance().get('test/api/sondage/anonyme')
            .then(function (response) {
                let data = response.data;
                if(data.length===0){
                    setQuizz([]);
                }else {
                    setQuizz(data);
                }
            })
            .catch(function (error) {
                setQuizz([]);
            });

    }
    useEffect(
        ()=>{
            fetchQuizz();
        },
        []
    )

    return <div>
        <ul className="pro-features">
            <a className="get-pro" ><i className="fa fa-plus-circle" aria-hidden="true"></i></a>
            <div className="button">
                <span className="btn"><a target="_blank" href='/register'>S'enregistrer</a></span>
                <span className="btn"><a target="_blank" href='https://client.youthfp.cm/login'>Se Connecte</a></span>
            </div>
            <br/>
            <hr/>
            {(quizz.length==0) ? <hr/> : <h6>QUIZZ</h6>}
            <div className="button">
                {quizz.map((quiz, index) => (
                    <a key={quiz.id} title={"Titre: " + quiz.name} onClick={()=>handleQuizClick(quiz.id)} className="btn">
                        <p>Quizz {(index + 1)}</p>
                    </a>
                ))}
            </div>
        </ul>
    </div>;

}

export default RegisterLogin;