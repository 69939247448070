import React, {useEffect} from "react";

function About() {

    useEffect(
        ()=>{
            document.title='A propos';
        }
    )
    return <section className="services section">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="section-title">
                        <h2>A PROPOS</h2>
                        <img src="img/section-img.png" alt="#" />
                            <p></p>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-6 aos-init aos-animate" data-aos="fade-up" data-aos-delay="150">
                    <p>A l’origine, dans le cadre de la célébration au Cameroun des 50 ans de l’UNFPA, des 25 ans de la
                        Conférence
                        Internationale sur la Population et le Développement (CIPD) et de la tenue du Sommet de Nairobi,
                        les jeunes des
                        réseaux CNJC, AFRIYAN ET RENATA s’étaient mis ensemble afin de mener des activités sur le
                        terrain. Il s’agissait
                        d’une campagne de sensibilisation et de mobilisation populaire en faveur de l’accélération de la
                        mise en œuvre du
                        Programme d’Action de la CIPD au Cameroun, dénommée ICPD Truck-Show. L’initiative s’est déroulée
                        durant le mois
                        d’octobre 2019.</p>
                    <p>A travers ce truck-Show, l’objectif était de mobiliser 80 000 femmes et jeunes dont au moins 30
                        000 devraient être
                        touchés au cours des échanges directs et 50 000 autres à travers les affiches et médias
                        classiques et sociaux. Suite
                        aux recommandations du Sommet de Nairobi sur les 50 ans de l’UNFPA et les 25 ans de la CIPD
                        d’une part, et au
                        lancement de la Décennie d’Action pour l’atteinte des Objectifs de Développement Durable (ODD)
                        d’autre part, l’UNFPA
                        veut développer la communication pour le changement des comportements des populations en vue
                        d’accélérer l’atteinte
                        des trois résultats transformateurs de son plan stratégique 2018-2030 au Cameroun :</p>
                    <ul>
                        <li><i className="fa fa-check"></i> Zéro besoin non-satisfait en planification familiale</li>
                        <li><i className="fa fa-check"></i> Zéro décès maternel évitable</li>
                        <li><i className="fa fa-check"></i> Zéro violence basée sur le genre et pratique culturelle
                            néfaste.
                        </li>
                    </ul>
                    <p>A cet effet, les plateformes des jeunes vont être mises sur pied dans les Régions du Centre, du
                        Littoral, de l’Est
                        et de l’Extrême-Nord. L’objectif est de mener des campagnes de sensibilisation récurrentes à
                        travers les médias
                        sociaux, les radios communautaires et locales et la mobilisation communautaire auprès des
                        jeunes, les femmes et les
                        personnes affectées ou déplacées du fait des crises. La finalité de ces campagnes était de
                        renforcer la protection
                        contre la Covid-19, d’assurer l’accès à l’information permettant à ces groupes cibles de jouir
                        de leurs droits et
                        choix en santé sexuelle et reproductive, à l’autonomisation et d’avoir accès aux services
                        disponibles.</p>
                </div>
                <div className="col-lg-6 pt-4 pt-lg-0 aos-init aos-animate" data-aos="fade-up" data-aos-delay="300">
                    <p>Dans cette perspective, les plateformes des jeunes pour la sensibilisation seront créées comme un
                        réseau
                        d’apprentissage et de partage d’expériences entre l’UNFPA, AfriYAN, le CNJC, RENATA, les médias
                        locaux et divers
                        autres groupes de jeunes intégrés sur une base volontaire et au regard de leur engagement sur
                        les thématiques de
                        population et développement. Ces plateformes auront les interventions aux niveau local, régional
                        et central. Après
                        la première phase de mise en place des plateformes qui a eu lieu entre octobre et novembre 2020
                        dans les Régions du
                        Centre, de l’Adamaoua, de l’Est et de l’Extrême-Nord, et du lancement des activités et
                        l’élargissement des
                        plateformes dans la Région du Centre en Février 2021, la plateforme des jeunes pour la
                        sensibilisation en vue
                        d’accélérer l’atteinte des trois résultats transformateurs pour l’accès à la santé sexuelle et
                        reproductive et la
                        protection des communautés contre la COVID-19 a été appelé à être totalement fonctionnel . Après
                        quelques mois de
                        fonctionnement, les responsables des différents réseaux de jeunes conscients de la lourde
                        mission ont émis le vœu
                        d’être accompagné par le MINJEC dans le cadre de la restructuration de la plateforme. C’est
                        ainsi que par la suite
                        avec l’appui de l’UNFPA et le soutient du MINJEC plusieurs séminaires et ateliers ont été
                        organisé sur la GAR, sur
                        la SSR des adolescents et jeunes afin de trouver la bonne formulation mais et surtout de mieux
                        circonscrire les
                        activités de la plateforme. A la suite de cela il y’a eu la mise à disposition des différents
                        réseaux membres le
                        matériel didactique pour un meilleur travail sur le terrain et la signature du MOU entre les
                        réseaux. Quelques mois
                        après cette signature le travail s’est poursuivi afin d’introduire d’autres d’acteurs afin de
                        rendre la plateforme
                        plus efficace. C’est à ce titre que les Organisations et Réseaux tels CAMANAFAW et le Réseau des
                        Jeunes du Cameroun
                        ont regagné la barque. Et nous avons entamé des réflexions. Lesdites réflexions qui conduisent
                        ce jour au lancement
                        de la plateforme numérique baptisée : Youth for Familial planning.</p>
                </div>
            </div>
        </div>
    </section>;

}

export default About;