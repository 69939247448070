import React, {useEffect, useState} from "react";
import axiosInstance from "../../../configurations/axiosInstance/AxiosInstance";

function Temoignages() {

    const [temoignages, setTemoignages] = useState([]);
    const fetchTemoignages = ()=>{
        axiosInstance().get('api/valeur/find-active')
            .then(function (response) {
                let datas = response.data;
                let fetchedTemoignages = [];
                if (datas != null) {
                    let data = datas[0];
                    try {
                        fetchedTemoignages.push(
                            <div className="single-features"key={data.id}>
                                <div className="signle-icon">
                                    <i className="icofont icofont-user"></i>
                                </div>
                                <h3>{data.title}</h3>
                                <p>{data.contenu}</p>
                            </div>
                    );
                    } catch (e) {
                    }
                    setTemoignages(fetchedTemoignages);
                } else {
                    setTemoignages([]);
                }
            })
            .catch(function (error) {
            });

    }
    useEffect(
        ()=>{
            fetchTemoignages();
        },
        []
    )
    return <section className="Feautes section">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="section-title">
                        <h2>TEMOIGNAGES</h2>
                        <img src="img/section-img.png" alt="#" />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12 col-12">
                    {temoignages}
                </div>
            </div>
        </div>
    </section>;

}
export default Temoignages;