import React, {useEffect, useState} from "react";
import axiosInstance from "../../configurations/axiosInstance/AxiosInstance";
import {toastClick} from "../toast/CustomToast";

function Register() {

    const [login, setLogin] = useState({
        prenom: "",
        nom: "",
        numero_telephone: "",
        sexe: "AUTRE",
        pwd: ""
    });

    const [active, setActive] = useState({
        prenom: false,
        nom: false,
        numero_telephone: false,
        sexe: true,
        pwd: false
    });

    const submitRegisterForm = (event) => {
        event.preventDefault(); // Prevent the form from submitting in the traditional way
        document.getElementById('preloader').classList.remove('preloader-deactivate');

        // Vérifier si tous les champs sont valides
        if (Object.values(active).every(value => value)) {
            axiosInstance().post('user/register', login)
                .then(function (response) {
                    document.getElementById('preloader').classList.add('preloader-deactivate');
                    toastClick("Success","success");
                    setLogin({
                        prenom: "",
                        nom: "",
                        numero_telephone: "",
                        sexe: "AUTRE",
                        pwd: ""
                    });
                    setActive({
                        prenom: false,
                        nom: false,
                        numero_telephone: false,
                        sexe: true,
                        pwd: false
                    });

                    window.open("https://client.youthfp.cm/login", "_blank");
                })
                .catch(function (error) {
                    document.getElementById('preloader').classList.add('preloader-deactivate');
                    toastClick("Echec","error");
                });
        } else {
            document.getElementById('preloader').classList.add('preloader-deactivate');
            toastClick("Champs vide","info");
        }
    };

    const checkPasswordValue = (event) => {
        const message = event.target.value.toString().trim();
        if (message.length < 8) {
            document.getElementById('cpwd').disabled = true;
        } else {
            setLogin(prevState => ({ ...prevState, pwd: message }));
            document.getElementById('cpwd').disabled = false;
        }
    }

    const checkNameValue = (event) => {
        const message = event.target.value.toString().trim();
        if (message.length < 3) {
            document.getElementById('name').style.border= '1px solid #f20c0c';
            setActive(prevState => ({ ...prevState, nom: false }));
        } else {
            setLogin(prevState => ({ ...prevState, nom: message }));
            document.getElementById('name').style.border= '1px solid green';
            setActive(prevState => ({ ...prevState, nom: true }));
        }
    }

    const checkSurNameValue = (event) => {
        const message = event.target.value.toString().trim();
        if (message.length < 3) {
            document.getElementById('surname').style.border= '1px solid #f20c0c';
            setActive(prevState => ({ ...prevState, prenom: false }));
        } else {
            setLogin(prevState => ({ ...prevState, prenom: message }));
            document.getElementById('surname').style.border= '1px solid green';
            setActive(prevState => ({ ...prevState, prenom: true }));
        }
    }

    const checkPhoneValue = (event) => {
        const message = event.target.value.toString().trim();
        if (message.length < 3) {
            document.getElementById('phone').style.border= '1px solid #f20c0c';
            setActive(prevState => ({ ...prevState, numero_telephone: false }));
        } else {
            setLogin(prevState => ({ ...prevState, numero_telephone: message }));
            document.getElementById('phone').style.border= '1px solid green';
            setActive(prevState => ({ ...prevState, numero_telephone: true }));
        }
    }

    const checkPasswordIsValid = (event) => {
        const message = event.target.value.toString().trim();
        if (message !== login.pwd) {
            document.getElementById('pwd').style.border= '1px solid #f20c0c';
            document.getElementById('cpwd').style.border= '1px solid #f20c0c';
            setActive(prevState => ({ ...prevState, pwd: false }));
        } else {
            document.getElementById('pwd').style.border= '1px solid green';
            document.getElementById('cpwd').style.border= '1px solid green';
            setActive(prevState => ({ ...prevState, pwd: true }));
        }
    }

    useEffect(() => {
        document.title = "S'enregistrer";
    }, []);

    return (
        <section className="contact-us section">
            <div className="container">
                <div className="inner">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="contact-us-left">
                                <div id="register"></div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="contact-us-form">
                                <h2>S'enregistrer</h2>
                                <p>Creer un compte.</p>
                                <form className="form" onSubmit={submitRegisterForm}>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <input type="text" onKeyUp={checkNameValue} name="name" id="name" placeholder="Nom" required />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <input type="text" onKeyUp={checkSurNameValue} name="surname" id="surname" placeholder="Prenom" required />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <input type="tel" onKeyUp={checkPhoneValue} name="phone" id="phone" placeholder="Phone" required />
                                            </div>
                                        </div>
                                        <br/>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <input type="password" onKeyUp={checkPasswordValue} name="pwd" id="pwd" placeholder="***..." required />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <input type="password" name="cpwd" id="cpwd" onKeyUp={checkPasswordIsValid} disabled placeholder="***..." required />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group login-btn">
                                                <button className="btn" type="submit">S'enregistrer</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Register;
