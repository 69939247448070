import React, {useEffect} from "react";

import L from "leaflet";
import "leaflet/dist/leaflet.css";
import location from "../img/cover/location.png";
import axiosInstance from "../../configurations/axiosInstance/AxiosInstance";
import {toast} from "react-toastify";

export default function CarteLeaflet() {

    let greenIcon = L.icon({
        iconUrl: "img/cover/location.png",
        iconSize: [38, 95], // size of the icon
        shadowSize: [50, 64], // size of the shadow
        iconAnchor: [22, 94], // point of the icon which will correspond to marker's location
        shadowAnchor: [4, 62],  // the same for the shadow
        popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
    });

    const fetchData = ()=>{
        let preloader=document.getElementById('preloader');
        preloader.classList.remove('preloader-deactivate');
        let listOfCP = new Array();
        axiosInstance().get('api/centrepartenaire/localisation')
            .then(function (response) {
                let data = response.data;
                if ((data == null) || (data.length == 0)) {
                } else {
                    for (let index = 0; index < data.length; index++) {
                        let popup = '<h3><strong>Nom: </strong>' + data[index].nom + '</h3>';
                        popup += '<p><strong>Localisation: </strong>' + data[index].location + '</p>';
                        popup += '<p><strong>Telephone: </strong>' + data[index].telephone + '</p>';
                        popup += '<p><a href="cp/'+data[index].id+'">En savoir plus.</a></p>';
                        let mq = L.marker([data[index].latittude, data[index].longitude], { icon: greenIcon }).bindPopup(popup);
                        listOfCP.push(mq);
                    }

                }
                let cp = L.layerGroup(listOfCP);
                let openstreetmap = L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
                    maxZoom: 19,
                    attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                });

                let googleStreets = L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
                    maxZoom: 20,
                    subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
                });

                let googleHybrid = L.tileLayer('http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}', {
                    maxZoom: 20,
                    subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
                });
                let map = L.map('map', {
                    layers: [openstreetmap, cp]
                });


                let baseMaps = {
                    "Google Map Hybrid": googleHybrid,
                    "Google Streets": googleStreets,
                    "Open Street Map": openstreetmap

                };

                let overlayMaps = {
                    "Centre Partenaire": cp
                };
                let layerControl = L.control.layers(baseMaps, overlayMaps).addTo(map);
                map.locate({ setView: true, maxZoom: 16 });
                function onLocationFound(e) {
                    var radius = e.accuracy;
                    L.circle(e.latlng, radius).addTo(map);
                }

                map.on('locationfound', onLocationFound);
                function onLocationError(e) {
                    toast("Impossible d'avoir votre localisation", 'info');
                }
                map.on('locationerror', onLocationError);

            })
            .catch(function (error) {
                let cp = L.layerGroup([]);
                let openstreetmap = L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
                    maxZoom: 19,
                    attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                });

                let googleStreets = L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
                    maxZoom: 20,
                    subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
                });

                let googleHybrid = L.tileLayer('http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}', {
                    maxZoom: 20,
                    subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
                });
                let map = L.map('map', {
                    layers: [openstreetmap, cp]
                });


                let baseMaps = {
                    "Google Map Hybrid": googleHybrid,
                    "Google Streets": googleStreets,
                    "Open Street Map": openstreetmap

                };

                let overlayMaps = {
                    "Centre Partenaire": cp
                };
                let layerControl = L.control.layers(baseMaps, overlayMaps).addTo(map);
                map.locate({ setView: true, maxZoom: 16 });
                function onLocationFound(e) {
                    let radius = e.accuracy;
                    L.circle(e.latlng, radius).addTo(map);
                }

                map.on('locationfound', onLocationFound);
                function onLocationError(e) {
                    toast("Impossible d'avoir votre localisation", 'info');
                }
                map.on('locationerror', onLocationError);
            });

        preloader.classList.add('preloader-deactivate');


    }


    useEffect(
        ()=>{
            document.title='Carte';
            fetchData();
        },[]
    )

    return <div id="map" style={
        {
            height: '600px'
        }
    }></div>;

}