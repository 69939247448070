import React from "react";

function Footer() {

    return (
        <>
            <footer id="footer" className="footer ">
                <div className="copyright">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-12">
                                <div className="copyright-content">
                                    <p>© Copyright AfriYAN Cameroun. All Rights Reserved by </p>
                                    <span>Designed by Plateforme des Jeunes du Cameroun</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

        </>
    );

}

export default Footer;