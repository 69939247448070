import React, {useEffect, useState} from "react";
import axiosInstance from "../../configurations/axiosInstance/AxiosInstance";

export default function Services() {
    const [services, setServices] = useState([]);

    const fetchServices = ()=>{
        document.getElementById('preloader').classList.remove('preloader-deactivate');
        axiosInstance().get('service')
            .then(function (response) {
                let data = response.data;
                let fetchedServices = [];
                if((data == null)||(data.length===0)){
                    fetchedServices.push(
                        <div>
                            <p>Cet Articles n'existe pas / plus</p>
                        </div>
                    );
                }else{
                    for (let index = 0; index < data.length; index++) {
                        fetchedServices.push(
                            <tr style={
                                {
                                    minHeight: '230px',
                                    maxHeight: '230px'
                                }
                            }>
                                <td style={
                                    {
                                        width: '230px',
                                        padding: 0
                                    }
                                }><img src="img/cover/Health-Insurance.jpg"
                                       width="230px" height="230px"
                                       style={
                                           {
                                               minHeight: '230px',
                                               maxHeight: '230px'
                                           }
                                       } />
                                </td>
                                <td>
                                    <div>
                                        <p><strong>Nom: </strong>{data[index].libelle}</p>
                                        <p><strong>Centre Partenaire: </strong>{data[index].centrePartenaire.nom}</p>
                                        <p><strong>Telephone: </strong>{data[index].centrePartenaire.telephone}</p>
                                        <p><strong>localisation: </strong>{data[index].centrePartenaire.location}</p>
                                        <div><a href="https://client.youthfp.cm/login" className="btn">Souscrire</a>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        )
                    }
                }
                document.getElementById('preloader').classList.add('preloader-deactivate');
                setServices(fetchedServices);
            })
            .catch(function (error) {
                let fetchedServices = [];
                fetchedServices.push(
                    <div>
                        <p>Cet Articles n'existe pas / plus</p>
                    </div>
                );
                setServices(fetchedServices);
                document.getElementById('preloader').classList.add('preloader-deactivate');
            });
    }

    useEffect(
        ()=>{
            document.title='Services';
            fetchServices();
        },
        []
    );



    return (services.length===0) ? (<></>):(
        <section className="blog section" id="blog">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="section-title">
                            <h2>SERVICES</h2>
                            <img src="img/section-img.png" alt="#" />
                                <p></p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">

                        <div className="table-responsive-lg">
                            <table className="table">
                                <tbody>
                                {services}
                                </tbody>
                            </table>
                        </div>
                    </div>


                </div>
            </div>
        </section>
    );

}