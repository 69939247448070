import React, {useEffect} from "react";
import Slider from "./slider/Slider";
import ListArticle from "./articles/list/ListArticle";
import RegisterLogin from "../registerlogin/RegisterLogin";
import Valeurs from "./valeur/Valeurs";
import Temoignages from "./temoignages/Temoignages";
import AboutLink from "./aboutlink/AboutLink";
import Realisations from "./Realisations/Realisations";
import Themes from "./Themes/Themes";
import Partnaires from "./partenaires/Partnaires";
import Map from "./map/Map";

function Home() {

    useEffect(
        ()=>{
            document.title='Acceuil';
            let preloader=document.getElementById('preloader');
            preloader.classList.remove('preloader-deactivate');
            setTimeout(
                ()=>{
                    preloader.classList.add('preloader-deactivate');
                },
                5000
            )

        }
    )
    return <>
        <Slider />
        <ListArticle />
        <Valeurs />
        <Temoignages />
        <AboutLink />
        <Realisations />
        <Themes />
        <Partnaires />
        <br />
        <hr />
        <br />
        <Map />

    </>;

}

export default Home;